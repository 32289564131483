import React from 'react';
import { IonContent, IonHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonToggle, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Tab3.css';
import { moon, warning } from 'ionicons/icons';
const toggleDarkModeHandler = () => document.body.classList.toggle('dark');
const Tab3: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>ToS</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ToS</IonTitle>
            <IonIcon size="large" slot="end" icon={moon} />
            <IonToggle slot="end" name="darkMode3" aria-checked="false" onIonChange={toggleDarkModeHandler} />
          </IonToolbar>
        </IonHeader>
        <div className="padd">
          <IonCard >
            <div className="tengah flare"><IonIcon icon={warning} class="larger" /></div>
            <IonCardHeader>
              <IonCardSubtitle>deposit withdraw</IonCardSubtitle>
              <IonCardTitle>Term of Service </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <ul>
                <li>
                  ditukar.in bukan merupakan staff pialang berjangka manapun dan tidak bertindak atas nama pialang berjangka manapun.
                </li>
                <li>
                  Kami tidak menghimpun dana klien atau menjalankan investasi untuk klien.
                </li>
                <li>
                  <strong>Jasa ini semata hanya menyediakan bantuan dalam hal pertukaran mata uang.</strong>
                </li>
                <li>
                  Order akan diproses beberapa menit sampai beberapa jam tergantung antrian karena banyaknya client.
                </li>
                <li>
                  Bijaksanalah dengan margin anda, jika mengalami floating loss dan kami telat memprosesnya sehingga terjadi margin call, bukan tanggungjawab kami.
                </li>
                <li>
                  Akun yang dilock broker hanya bisa diwithdraw dengan diantrikan ke pusat.
                </li>
              </ul>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
