import React, { useState, useEffect} from "react";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonToggle,
  IonIcon,
  IonRow,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonCardHeader,
  IonButton,
  IonInput
} from "@ionic/react";
import "./Tab1.css";
import { moon, flash, code, closeCircleOutline, timer, logoBuffer, list, paperPlane, cloudUpload, cloudDownload, create } from "ionicons/icons";
const toggleDarkModeHandler = () => document.body.classList.toggle("dark");

const Tab1: React.FC = () => {
  const [akun, setAkun] = useState<number>(0);
  const [ada, setAda] = useState('');
  const [rate, setRate] = useState<number>(0);

  const Cekakun = ()=> {
    setAda('load');
    fetch('https://instaforex.io/cek/'+akun)
  .then(response => response.json())
  .then(data => setAda(data.toString()));
  }

  useEffect(() => {
    fetch('https://sheets.googleapis.com/v4/spreadsheets/17o4_8aqp8e0Onm7CeDWll-YzQ04XYPJDWTRUIdzSlXc/values/A?range=rate&key=AIzaSyC2zHpU-hKGEdSWeJjq1LQKIbUVt_dWcD4')
      .then(res => res.json())
      .then(data => setRate(data['values'][0][0]));
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>ditukar.in</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ditukar.in</IonTitle>
            <IonIcon size="large" slot="end" icon={moon} />
            <IonToggle slot="end" aria-checked="false" name="darkMode1" onIonChange={toggleDarkModeHandler} />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <div className="tengah red">
                    <IonIcon icon={flash} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Kecepatan deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>Instant</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Deposit withdraw melalui kami sangatlah cepat. Kami bisa memproses instant atau beberapa menit tergantung banyaknya antrian.</IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah cek">
                      <IonItem>
                        <IonLabel>Nomor Akun :</IonLabel>
                        <IonInput name="akun" type="number" value={akun} onIonChange={e => setAkun(parseInt(e.detail.value!))}/>
                        <IonButton type="submit" onClick={Cekakun}>Periksa</IonButton>
                      </IonItem>
                      <p className="pc pcp" id="cek">{
                      ada === '' ? 'Periksa Akun Anda '+(akun===0?'':akun)
                      : ada === 'load' ? 'Loading...'
                      : ada === 'true' ? 'Selamat! Akun anda bisa kami proses flat rate deposit withdraw Rp. '+rate+'/$ '
                      : 'Maaf, Akun Anda tida ada, deposit rate '+rate+' dan withdraw rate '+(rate-500)+'. Untuk mendapatkan flat rate, silahkan buka akun baru'}</p>
                      {ada==='false'?<span><a href='https://bukaakun.com'><IonButton size='small' color='warning'>Buka Akun</IonButton></a></span>:<span></span>}
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>instant & flat rate</IonCardSubtitle>
                    <IonCardTitle>Cek Akun</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Periksa akun anda disini, untuk mendapatkan flat rate dan proses instant waktu melakukan deposit withdraw</IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah green">
                    <div className="atasbawahr">
                      <p className="rate" id="rate">{rate}</p>
                    </div>
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>Rate</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Rate withdraw -500 untuk non affiliasi. Rate bisa berubah mengikuti harga pasar dan jumlah ketersediaan dan permintaan.</IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonCard>
                  <div className="tengah rainbow">
                    <IonIcon icon={code} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Rate deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>Flat (untuk affiliasi)</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Flat rate. Rate deposit sama dengan rate withdraw untuk client yang dibawah affiliasi. Jadi selama rate belum berubah, klien tidak dirugikan dengan selisih rate.
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah martini">
                    <IonIcon icon={closeCircleOutline} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>No fee & no charge</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Kami membantu proses deposit withdraw tanpa biaya dan tanpa potongan. Jadi klien lebih diuntungkan waktu melakukan deposit withdraw.</IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah flare">
                    <IonIcon icon={timer} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Hampir non-stop 17/7</IonCardSubtitle>
                    <IonCardTitle>8am - 1am</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Kami memproses deposit withdraw hampir 24 jam nonstop. Dari jam 8 pagi sampai jam 1 dini hari (atau lebih), dari hari senin sampai senin lagi.</IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonCard>
                  <div className="tengah blue">
                    <IonIcon icon={logoBuffer} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Rekening lengkap</IonCardSubtitle>
                    <IonCardTitle>Bank lokal</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Kami menyediakan rekening bank lokal yang sangat lengkap untuk proses deposit withdraw. Jadi klien tidak akan kena potongan transfer antar bank lokal.
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah purple">
                    <IonIcon icon={list} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Order deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>Tanpa isi form</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Kami memproses deposit withdraw tanpa isi form dan tanpa login kabinet. Jadi klien tidak susah dan tidak ribet.</IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah purple2">
                    <IonIcon icon={create} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Order deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>Tanpa registrasi</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Tidak seperti ditempat lain, kami bisa memproses deposit withdraw akun client, tanpa client harus registrasi dan verifikasi.</IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonCard>
                  <div className="tengah biru">
                    <IonIcon icon={paperPlane} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Order deposit withdraw</IonCardSubtitle>
                    <IonCardTitle>Semudah chat</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>Hanya dengan menggunakan Whatsapp, telegram atau messenger, kami bisa memproses deposit withdraw anda.</IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah rainbowblue">
                    <IonIcon icon={cloudUpload} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Konfirmasi</IonCardSubtitle>
                    <IonCardTitle>Deposit</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Untuk melakukan deposit, anda hanya butuh transfer ke salah satu bank kami, kemudian kontak bagian finance, diinfokan transfer berapa ke bank apa untuk akun berapa. Nanti kami cek
                    trus kami proses.
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <div className="tengah hijau">
                    <IonIcon icon={cloudDownload} class="larger" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>Konfirmasi</IonCardSubtitle>
                    <IonCardTitle>Withdraw</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Untuk melakukan withdraw, anda hanya butuh konfirmasi ke kami, withdraw berapa dari akun berapa, jumlah berapa dan ditransfer ke rekening bank apa. Nanti kami cek kemudian kami
                    proses.
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <div className="atasbawah"></div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
