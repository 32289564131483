import React from 'react';
import { IonContent, IonHeader, IonList, IonItem, IonLabel, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonToggle, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Tab4.css';
import { moon, chatboxEllipses, logoWhatsapp, chatbubbleEllipses, paperPlane, chevronForwardCircleOutline } from 'ionicons/icons';
const toggleDarkModeHandler = () => document.body.classList.toggle('dark');
const Tab4: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Kontak</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Kontak</IonTitle>
            <IonIcon size="large" slot="end" icon={moon} />
            <IonToggle slot="end" name="darkMode4" aria-checked="false" onIonChange={toggleDarkModeHandler} />
          </IonToolbar>
        </IonHeader>
        <div  className="padd">
        <IonCard>
            <div className="tengah martini"><IonIcon icon={chatboxEllipses} class="larger"/></div>
            <IonCardHeader>
              <IonCardSubtitle>deposit withdraw</IonCardSubtitle>
              <IonCardTitle>Support</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
              <IonList>
                <IonItem>
                <IonIcon size="large" slot="start" icon={logoWhatsapp}/>
                  <IonLabel>WhatsApp<br /> +447480820464</IonLabel>
                  <IonIcon size="large" slot="end" icon={chevronForwardCircleOutline} onClick={()=>window.open('https://wa.me/447480820464')}/>
                </IonItem>
                <IonItem>

                    <IonIcon size="large" slot="start" icon={chatbubbleEllipses}/>

                  <IonLabel>Messenger<br />  +447480820464</IonLabel>
                  <IonIcon size="large" slot="end" icon={chevronForwardCircleOutline} onClick={()=>window.open('https://m.me/ditukar.in')}/>
                </IonItem>
                <IonItem>
                <IonIcon size="large" slot="start" icon={paperPlane}/>
                  <IonLabel>Telegram <br /> +447480820464</IonLabel>
                  <IonIcon size="large" slot="end" icon={chevronForwardCircleOutline} onClick={()=>window.open('https://t.me/ditukarin')}/>
                </IonItem>
              </IonList>
              </IonCardContent>
             </IonCard>
             </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab4;
