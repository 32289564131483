import React from 'react';
import { IonContent, IonHeader, IonIcon, IonToggle, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonCard, IonCardContent } from '@ionic/react';
import './Tab2.css';
import { moon } from 'ionicons/icons';
const toggleDarkModeHandler = () => document.body.classList.toggle('dark');
const Tab2: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Wallet & Bank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Wallet & Bank</IonTitle>
            <IonIcon size="large" slot="end" icon={moon} />
            <IonToggle slot="end" aria-checked="false" name="darkMode2" onIonChange={toggleDarkModeHandler} />
          </IonToolbar>
        </IonHeader>
        <IonGrid class="pad">

          <IonRow>

            <IonCol>
              <IonCard class="wputih">
                <IonCardContent>
                  <div >
                    <img alt="gopay" src="../assets/imgs/gopay.png" className="wl" />
                  </div>
                  <div className="wb">
                    <img alt="gopay" src="../assets/imgs/gopayq.jpeg" className="barcode" />
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol>
              <IonCard class="wputih">
                <IonCardContent>
                  <div >
                    <img alt="ovo" src="../assets/imgs/ovo.png" className="wl" />
                  </div>
                  <div className="wb">
                    <img width="300px" alt="ovo" src="../assets/imgs/ovoq.jpeg" className="barcode" />
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>


            <IonCol>
              <IonCard class="wputih">
                <IonCardContent>
                  <div>
                    <img alt="dana" src="../assets/imgs/dana.png" className="wl" />
                  </div>
                  <div className="wb">
                    <img alt="dana" src="../assets/imgs/danaq.jpeg" className="barcode" />
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol>
              <IonCard class="wputih">
                <IonCardContent>
                  <div>
                    <img alt="link" src="../assets/imgs/link.png" className="wl" />
                  </div>
                  <div className="wb">
                    <img alt="link" src="../assets/imgs/linkq.jpeg" className="barcode" />
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  bblue">
                <div className="wputih">
                  <img alt="bca" src="../assets/imgs/bca.png" /></div>
                <img alt="bca" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">3270467XXX</p>
                      <div className="vm">
                        <img alt="bca" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  bblue">
                <div className="wputih">
                  <img alt="mandiri" src="../assets/imgs/mandiri.png" /></div>
                <img alt="mandiri" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">1370004651XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="mandiri" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  byellow">
                <div className="wputih">
                  <img alt="bni" src="../assets/imgs/bni.png" /></div>
                <img alt="bni" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">0229717XXX</p>
                      <div className="vm">
                        <img alt="bni" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  bblue">
                <div className="wputih">
                  <img alt="bri" src="../assets/imgs/bri.png" /></div>
                <img alt="bri" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">033401036072XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="bri" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  bpink">
                <div className="wputih">
                  <img alt="niaga" src="../assets/imgs/niaga.png" /></div>
                <img alt="niaga" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">701050509XXX</p>
                      <div className="vm">
                        <img alt="niaga" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  byellow">
                <div className="wputih">
                  <img alt="danamon" src="../assets/imgs/danamon.png" /></div>
                <img alt="danamon" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">003539929XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="danamon" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  bgreen">
                <div className="wputih">
                  <img alt="bukopin" src="../assets/imgs/bukopin.png" /></div>
                <img alt="bukopin" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">1101029XXX</p>
                      <div className="vm">
                        <img alt="bukopin" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  bgreen">
                <div className="wputih">
                  <img alt="permata" src="../assets/imgs/permata.png" /></div>
                <img alt="permata" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">4110760XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="permata" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  bgreen">
                <div className="wputih">
                  <img alt="syariah" src="../assets/imgs/syariah.png" /></div>
                <img alt="syariah" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">7766644XXX</p>
                      <div className="vm">
                        <img alt="syariah" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  bblue">
                <div className="wputih">
                  <img alt="panin" src="../assets/imgs/panin.png" /></div>
                <img alt="panin" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">3072038XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="panin" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  bblue">
                <div className="wputih">
                  <img alt="btn" src="../assets/imgs/btn.png" /></div>
                <img alt="btn" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">0015001500067XXX</p>
                      <div className="vm">
                        <img alt="btn" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  byellow">
                <div className="wputih">
                  <img alt="mega" src="../assets/imgs/mega.png" /></div>
                <img alt="mega" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">013360022001XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="mega" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard class="  bpink">
                <div className="wputih">
                  <img alt="dbs" src="../assets/imgs/dbs.jpeg" /></div>
                <img alt="dbs" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <IonRow>
                    <IonCol>
                      <p className="pr">1704173XXX</p>
                      <div className="vm">
                        <img alt="dbs" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard class="  bblue">
                <div className="wputih">
                  <img alt="ocbc" src="../assets/imgs/ocbc.png" /></div>
                <img alt="ocbc" src="../assets/imgs/chip.png" className="chip" />
                <IonCardContent class="padcard">
                  <p className="pr">693810165XXX</p>
                  <IonRow>
                    <IonCol>
                      <div className="vm">
                        <img alt="ocbc" src="../assets/imgs/visamaster.png" className="visamaster" />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="atasbawah"></div>
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
